import { validateForms } from '../functions/validate-forms';

const rules1 = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 2,
        errorMessage: 'Введите 2 и более символов'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя'
      },
    ]
  },
  {
    ruleSelector: '.input-mail',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните email'
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Введите корректный Email'
      }
    ]
  },
];

const afterForm = () => {
  console.log('Отправлено');
};

validateForms('.form__order', rules1, afterForm);

const rules2 = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 2,
        errorMessage: 'Введите 2 и более символов'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя'
      },
    ]
  },
  {
    ruleSelector: '.input-mail',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните email'
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Введите корректный Email'
      }
    ]
  },
];

const afterModal = () => {
  console.log('Отправлено');
};

validateForms('.form__modal', rules2, afterModal);
