import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

const swiper = new Swiper('.slider__content', {
  grabCursor: true,
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 40,

  zoom: {
    maxRatio: 1.2,
    minRation: 1
},

  navigation: {
    nextEl: '.slider__slider-btn--next',
    prevEl: '.slider__slider-btn--prev',
  }
});
